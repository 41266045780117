import {get } from "./http.js"
import {get2,post } from "./http2.js"

export function  getNews(){
    return get("/agent/api/pub/news/good?type=1")
}
export function  fetchNum(){
    return get("/agent/api/pub/web/data")
}

export function  fetchJobInfo(){
    return get("/agent/api/pub/jobinfo")
}

export function fetchNewsDetail(data){
    return get("/agent/api/pub/news/"+data.id)
}
export function fetchNewsList(data){
    return get("/agent/api/pub/news/list",data)
}
export function fetchTransportindex(data){
    return get("/agent/api/pub/news/list/transportindex",data)
}

export function fetchWeather(data){
    return get2("https://www.yiketianqi.com/free/day?appid=93848221&appsecret=E9CrKx39&unescape=1",data)
}


export function fetchWeatherList(data){
    return get2("https://www.yiketianqi.com/free/day?appid=93848221&appsecret=E9CrKx39&unescape= ",data)
}

export function getCity(){
    return get2("http://pv.sohu.com/cityjson?ie=utf-8")
}


export function getLucky(data){
    return post("/api/lottery/clickLottery",data)
}

export function report(){
    return get2("https://www.yundundun.com/2023year/1.json")
}


